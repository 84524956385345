<template>
  <div>
    <div :class="['select-custom ', { error: isValidate }]">
      <label v-if="title" class="custom-label">
        {{ title }}
        <span v-if="isRequired">*</span>
      </label>
      <br v-if="title" />

      <b-form-select
        v-bind:options="options"
        :name="name"
        :size="size"
        v-bind:value="value"
        :required="required"
        @input="$emit('input', $event)"
        :class="['btn-select']"
        @change="onDataChange"
        :valueField="valueField"
        :textField="textField"
        ref="input"
        :disabled="isDisplay"
        class="f-regular"
      >
        <template #first>
          <b-form-select-option
            :value="null"
            disabled
            selected
            v-if="value == null"
          >
            <span v-if="options.length > 0">
              --
              <slot name="options-name">{{
                placeholder || "กรุณาเลือกข้อมูล"
              }}</slot>
              --</span
            >
            <span v-else>-- ไม่มีข้อมูล --</span>
          </b-form-select-option>
          <b-form-select-option
            :value="'N/A'"
            disabled
            selected
            v-else-if="value == 'N/A'"
          >
            <span v-if="options.length > 0">
              --
              <slot name="options-name">{{
                placeholder || "กรุณาเลือกข้อมูล"
              }}</slot>
              --</span
            >
            <span v-else>-- ไม่มีข้อมูล --</span>
          </b-form-select-option>
          <b-form-select-option value="" disabled selected v-else>
            <span v-if="options.length > 0">
              --
              <slot name="options-name">{{
                placeholder || "กรุณาเลือกข้อมูล"
              }}</slot>
              --</span
            >
            <span v-else>-- ไม่มีข้อมูล --</span>
          </b-form-select-option>
        </template>
      </b-form-select>
    </div>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">{{
        validateText || "กรุณาเลือกข้อมูล"
      }}</span>
      <span class="text-error" v-else-if="v.minValue == false">{{
        options[0][textField || "text"]
      }}</span>
      <span class="text-error" v-if="v.valueDefault == false">{{
        options[0][textField || "text"]
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
      type: String
    },
    text: {
      required: false,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    name: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    },
    options: {
      required: true,
      type: Array
    },
    value: {
      required: false,
      type: Number | String
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    valueField: {
      required: false,
      type: String
    },
    textField: {
      required: false,
      type: String
    },
    isDisplay: {
      required: false,
      type: Boolean
    },
    placeholder: {
      required: false
    },
    validateText: {
      required: false
    }
  },
  data() {
    return {
      selected: 0
    };
  },
  mounted() {
    this.optionFilter();
  },
  methods: {
    optionFilter() {
      this.$nextTick(() => {
        let check = this.options.find(el => el.name == this.value);

        if (!check) {
          this.value = "";
        }
      });
    },
    onDataChange(event) {
      this.$emit("onDataChange", event);
    },
    focus() {
      this.$refs.input.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.select-custom {
  // margin-bottom: 15px;
  position: relative;
  white-space: nowrap;

  // padding-bottom: 15px;
}
.select-custom.error {
  border-color: red !important;
}
.select-custom.error > .btn-select {
  border-color: red !important;
}
.select-custom > label {
  color: black;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
  width: 30%;
  // margin: auto;
}

::v-deep .btn-select.custom-select-lg {
  height: 45px;
  font-size: 1rem;
}

::v-deep .btn-select:focus {
  border-color: gray;
}
::v-deep .btn-select > option {
  background-color: white;
  color: gray;
}
::v-deep .btn-select > option:checked {
  background-color: gray !important;
  color: white !important;
}
::v-deep .btn-select:focus {
  box-shadow: unset !important;
}

.text-error {
  color: red;
  font-size: var(--text-md);
}
</style>
