<template>
  <div>
    <b-modal
      size=""
      v-model="showModal"
      :title="changeTitle()"
      dialog-class="modal-fullscreen"
      hide-footer
    >
      <Policy v-if="type == 'policy'" />
      <Term v-else-if="type == 'term'" :brand="brand" :isPdf="isPdf" />
    </b-modal>
  </div>
</template>

<script>
import Policy from "@/views/page/policy";
import Term from "@/views/page/terms";
export default {
  components: { Policy, Term },
  data() {
    return { showModal: false, type: "", brand: "", isPdf: false };
  },
  mounted() {
    this.$EventBus.$on("showModal", (type, brand, isPdf) => {
      this.brand = brand;
      this.isPdf = isPdf;
      this.showModal = true;
      this.type = type;
    });
    this.$EventBus.$on("hideModal", () => {
      this.showModal = false;
      this.type = "";
      this.brand = "";
      this.isPdf = false;
    });
  },
  methods: {
    changeTitle() {
      return this.type == "policy"
        ? "Privacy and policy"
        : "Privacy Notice";
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal {
  .modal-fullscreen {
    .modal-content {
      .modal-header {
        position: sticky;
        top: 0;
        z-index: 1000;
        background: white;
      }
      border-radius: unset !important;
    }
    width: 100% !important;
    max-width: unset !important;
    margin: 0 !important;
  }
  padding-left: unset !important;
}
</style>
