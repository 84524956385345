import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import vueDebounce from "vue-debounce";
import "vue-datetime/dist/vue-datetime.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./style.css";
import "@/services/axios";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueCookies from "vue-cookies";
import { Datetime } from "vue-datetime";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";
import liff from "@line/liff";
import InfiniteLoading from "vue-infinite-loading";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueMeta from "vue-meta";
import Vuelidate from "vuelidate";
import swal from "sweetalert";
import Footer from "@/components/Footer";

import {
  faChevronRight,
  faClipboardList,
  faCog,
  faFileAlt,
  faStickyNote,
  faEdit,
  faHospitalSymbol,
  faBirthdayCake,
  faEnvelope,
  faPhoneAlt,
  faTicketAlt,
  faSyncAlt,
  faNewspaper,
  faChevronLeft,
  faPercentage,
  faGift,
  faTimesCircle,
  faCheckCircle,
  faCheck,
  faEllipsisV,
  faTrophy,
  faHistory,
  faChevronDown,
  faChevronUp,
  faCalendarAlt,
  faPlus,
  faStar,
  faPencilAlt,
  faArrowAltCircleRight,
  faSignOutAlt,
  faCalendarCheck,
  faGifts,
  faQrcode,
  faInfoCircle,
  faTimes,
  faCamera,
  faHome,
  faCreditCard,
  faFilter,
  faCopy,
  faShare,
  faClock,
  faSlidersH,
  faFilePdf,
  faFileDownload,
  faArrowLeft,
  faTrashAlt,
  faBars,
  faList,
  faCrown,
  faMinusCircle,
  faPlusCircle,
  faExpand,
  faUser,
  faSearch,
  faCircle
} from "@fortawesome/free-solid-svg-icons";
import { faLine } from "@fortawesome/free-brands-svg-icons";
import VueCountdown from "@chenfengyuan/vue-countdown";
import OtpInput from "@bachdgvn/vue-otp-input";
import VueSlickCarousel from "vue-slick-carousel";
import moment from "moment";
import InputSelect from "@/components/input/InputSelect.vue";
import InputText from "@/components/input/InputText.vue";
import ModalMessage from "@/components/alert-modal/ModalMessage";
import ModalConfirm from "@/components/alert-modal/ModalConfirm";
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
const Slick = {
  install(Vue, options) {
    Vue.component("slick", VueSlickCarousel);
  }
};
Vue.use(Slick);
Vue.use(vueDebounce);
Vue.component("v-otp-input", OtpInput);
Vue.component("ModalMessage", ModalMessage);
Vue.component("ModalConfirm", ModalConfirm);
Vue.component("Footer", Footer);
Vue.use({
  // this is the required "install" method for Vue plugins
  install(Vue) {
    Vue.swal = swal;
    Vue.prototype.$swal = swal;
  }
});
Vue.use(VueMeta);
Vue.use(InfiniteLoading, {
  /* options */
});
Vue.use(BootstrapVue);
Vue.use(VueCookies);
Vue.component("datetime", Datetime);
Vue.use(Vuelidate);
Vue.use(BootstrapVueIcons);
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component(VueCountdown.name, VueCountdown);
Vue.component("input-select", InputSelect);
Vue.component("input-text", InputText);
library.add(
  faChevronRight,
  faClipboardList,
  faCog,
  faFileAlt,
  faStickyNote,
  faEdit,
  faChevronLeft,
  faGift,
  faTimesCircle,
  faCheckCircle,
  faCheck,
  faHistory,
  faPlus,
  faStar,
  faHospitalSymbol,
  faBirthdayCake,
  faEnvelope,
  faPhoneAlt,
  faTicketAlt,
  faSyncAlt,
  faNewspaper,
  faPercentage,
  faEllipsisV,
  faPencilAlt,
  faTrophy,
  faChevronDown,
  faChevronUp,
  faCalendarAlt,
  faArrowAltCircleRight,
  faSignOutAlt,
  faCalendarCheck,
  faGifts,
  faQrcode,
  faInfoCircle,
  faTimes,
  faCamera,
  faGift,
  faHome,
  faCreditCard,
  faFilter,
  faLine,
  faCopy,
  faShare,
  faClock,

  faSlidersH,
  faFilePdf,
  faFileDownload,
  faArrowLeft,
  faTrashAlt,
  faBars,
  faList,
  faCrown,
  faMinusCircle,
  faPlusCircle,
  faExpand,
  faUser,
  faSearch,
  faCircle
);

Vue.config.productionTip = false;

// Vue.prototype.$baseUrl = baseUrl();
Vue.prototype.$apiKey = "ChAtA7Ds40p";
Vue.prototype.$axios = axios;
Vue.prototype.$formatDate = "DD MMM YYYY";
Vue.prototype.$formatDateTime = "DD MMM YYYY HH:mm";
Vue.prototype.$liff = liff;
Vue.prototype.$liffUrl = process.env.VUE_APP_LIFF_URL;
Vue.prototype.$liff_ID_Login = process.env.VUE_APP_LIFF_ID_LOGIN;
Vue.prototype.$liff_ID_Game_Login = process.env.VUE_APP_LIFF_ID_GAME_LOGIN;
Vue.prototype.$THEME = process.env.VUE_APP_THEME_IMG_DOMAIN;
Vue.prototype.$BRAND = process.env.VUE_APP_BRAND;
Vue.prototype.$EventBus = new Vue();
Vue.prototype.$moment = moment;
Vue.prototype.handleImageSrc = e =>
  (e.target.src = require("@/assets/images/default-image.png"));
let endpoint = "";
let nissinTransaction = "";

if (process.env.NODE_ENV == "development") {
  nissinTransaction =
    "https://api-middleware-dev.dosetech.co/api/transactioncode/redeem";
  endpoint = "https://5t8z5pcj-8080.asse.devtunnels.ms/";
} else {
  nissinTransaction =
    "https://api-middleware-nissin.d-dots.com/api/transactioncode/redeem";
  endpoint = "https://alo-pre-prod-crm.d-dots.com/";
}

Vue.prototype.$Endpoint = endpoint;
Vue.prototype.$nissinTransaction = nissinTransaction;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
