<template>
  <div id="app">
    <div id="app-container">
      <Header v-if="isLoadingTheme && !isShowHeader" />
      <div class="container-router">
        <div
          :class="
            isShowing
              ? 'background-telephone-color'
              : background
              ? 'background-color'
              : 'background-image'
          "
        ></div>
        <router-view />
      </div>
      <div class="menu-panel" v-if="!isShowFooter">
        <div @click="$router.push('/transaction-summary')">
          <div :class="['icon-banner', { isActive: isProfile }]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
            >
              <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
              <path
                d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z"
              />
            </svg>
          </div>
          <span>History</span>
        </div>
        <div @click="$router.push('/profile')">
          <div :class="['icon-banner', { isActive: !isProfile }]">
            <svg
              v-if="isProfile"
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path
                d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"
              />
            </svg>
            <font-awesome-icon v-else :icon="['fas', 'user']" />
          </div>
          <span>Profile</span>
        </div>
      </div>
      <Modal />
    </div>
  </div>
</template>

<script>
import Header from "@/views/layout/Header/Header";
import Modal from "@/components/modal";
export default {
  components: {
    Header,
    Modal
  },
  data() {
    return {
      setData: "",
      theme: {},
      isLoadingTheme: false,
      background: false,
      isProfile: true
    };
  },
  async beforeCreate() {
    this.$liff.init({ liffId: this.$liff_ID_Login });
  },
  async created() {
    await this.getTheme();
  },

  watch: {
    "$route.path": {
      handler: function (val) {
        this.isProfile = val.includes("/transaction-summary") ? true : false;
        this.$EventBus.$emit("hideModal");
      },
      deep: true,
      immediate: true
    }
  },

  mounted() {},
  computed: {
    isShowing() {
      if (
        this.$route.name == "validatetelephone" ||
        this.$route.name == "confirmotp" ||
        this.$route.name == "confirmotpedit" ||
        this.$route.name == "editprofile-telephone"
      )
        return true;
      return false;
    },
    isShowFooter() {
      if (
        this.$route.name == "validatetelephone" ||
        this.$route.name == "confirmotp" ||
        this.$route.name == "confirmotpedit" ||
        this.$route.name == "editprofile-telephone" ||
        this.$route.name == "review" ||
        this.$route.name == "register" ||
        this.$route.name == "editprofile"
      )
        return true;
      return false;
    },
    isShowHeader() {
      document.body.style.setProperty("--margin-navbar", "80px");
      if (
        this.$route.name == "Transaction-Summary" ||
        this.$route.name == "Transaction-Summary-Detail" ||
        this.$route.name == "Transaction-Summary-Detail-RECEIPT" ||
        this.$route.name == "profile" ||
        this.$route.name == "editprofile"
      )
        return true;
      return false;
    }
  },
  methods: {
    invertColor(hex, bw) {
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
      }

      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }
      var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
      if (bw) {
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
      }
      // invert color components
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);
      // pad each with zeros and return
      return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
    },
    async getTheme() {
      this.$axios
        .get(
          "https://api-alothailand-campaign-prod.d-dots.com/api/Setting/theme"
        )
        .then(async data => {
          this.theme = data;
          this.$store.commit("setTheme", this.theme);

          this.background = data.result.themeBackgroundPage == 1 ? true : false;

          let bodyStyles = document.body.style;
          bodyStyles.setProperty(
            "--primary-color",
            data.result.themePrimaryColor
          );
          bodyStyles.setProperty(
            "--secondary-color",
            data.result.themeSecondaryColorLine
          );
          bodyStyles.setProperty(
            "--disable-color",
            data.result.themeDisableColor
          );
          bodyStyles.setProperty(
            "--font-color",
            this.invertColor(data.result.themeSecondaryColor, true)
          );
          bodyStyles.setProperty(
            "--font-primary-color",
            this.invertColor(data.result.themePrimaryColor, true)
          );
          bodyStyles.setProperty(
            "--theme-background-color",
            data.result.themeSolidColor
          );
          bodyStyles.setProperty(
            "--theme-navigation-bar-color",
            data.result.themeNavigationBarColor
          );
          bodyStyles.setProperty(
            "--theme-background-image",
            `url(${data.themeLogoDomain}${data.result.themeBackGround})`
          );
          if (data.result.custom_font) {
            var newStyle = document.createElement("style");
            let CustomFont = "CustomFont";
            newStyle.appendChild(
              document.createTextNode(
                "\
@font-face {\
    font-family: " +
                  CustomFont +
                  ";\
    src: url('" +
                  data.themeLogoDomain +
                  data.result.custom_font.substring(1) +
                  "');\
                font-weight: '400';\
}\
"
              )
            );

            document.head.appendChild(newStyle);
          }
          this.isLoadingTheme = true;
        });
    }
  }
};
</script>
<style lang="scss">
:root {
  --margin-navbar: 80px;
}
.content {
  position: fixed;
  background-color: #fff;
  width: 100%;
}
/* .background-b {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    0deg,
    var(--secondary-color) 0%,
    var(--primary-color) 100%
  );
} */
.background-color {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: fixed;
  width: 100%;
  top: 0;
  min-height: 100vh;
  z-index: -1;
  background-color: var(--theme-background-color);
}
.background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: -1;
  /* overflow: hidden; */
  background-image: var(--theme-background-image);
}
.background-telephone-color {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: fixed;
  width: 100%;
  top: 0;
  min-height: 100vh;
  z-index: -1;
  background-color: white;
}
.menu-panel {
  border-top: 0.5px solid #d0d0d0;
  position: fixed;
  bottom: 0;
  background: rgb(250, 250, 250);
  width: 100%;
  height: auto;
  padding: 1rem;
  // border-radius: 0.25rem;
  display: flex;
  > div:first-child {
    // border-right: 1px solid #afafaf;
  }
  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
    font-size: 14px;
  }
}
.icon-banner {
  &.isActive {
    opacity: 1;
  }
  opacity: 0.4;
}
</style>
