import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
import Vuex from "vuex";
import router from "../router";
import liff from "@line/liff";
Vue.use(VueCookies);
Vue.use(Vuex);
export const store = new Vuex.Store({
  state: {
    shortProfile: {
      picture: null,
      profile: null,
      email: "",
      first_name_th: "",
      last_name_th: "",
      first_name_en: "",
      last_name_en: "",
      nickname: "",
      telephone: "",
      birthday: "",
      gender: "",
      total_point: 0,
      home_address: "",
      town: "",
      alley: "",
      road: "",
      subdistrict: "",
      district: "",
      province: "",
      zip_code: "",
      is_ready: false,
      user_guid: ""
    },
    completeProfile: {},
    theme: {
      is_custom_privacy_link: "0",
      is_center_point: "0",
      consent_url_link: "",
      is_right_request_url: "0",
      right_request_url: "",
      is_term_and_con_url: "0",
      term_and_con_url: "",
      themeBackGround: "",
      themeBackgroundPage: "",
      themeDisableColor: "",
      themeLightPrimaryColor: "",
      line_display_code_countdown: "",
      line_display_right_request_link: "",
      line_display_logout_button: "",
      themeLogoHomePage: "",
      themeLogoLine: "",
      themeLogoLoginPage: "",
      themePrimaryColor: "",
      themePrintLogo: "",
      themeSecondaryColor: "",
      themeSolidColor: "",
      themeTransactionCodeBanner: "",
      themeTransactionCodeEarn: "",
      themeTransactionCodeError: ""
    },
    editPhonenumber: false,
    domain: ""
  },
  mutations: {
    setShortProfile: async function (state, val) {
      if (val) {
        state.shortProfile = val;
      } else {
        state.authenticate = false;
      }
    },
    setTheme: async function (state, val) {
      state.theme = val.result;
      state.domain = val.themeLogoDomain;
    },
    setEditPhoneNumber: function (state, val) {
      state.editPhonenumber = val;
    }
  },
  actions: {
    getUserApi: async ({ commit, rootGetters, state }, payload) => {
      // if (state.shortProfile.is_ready) {
      //   return state.shortProfile;
      // }
      const res = await axios(
        `${process.env.VUE_APP_API}/api/v1/user/GetUserProfile`
      );
      if (res.result == 1) {
        let bd = res.detail.birthday;
        if (new Date(bd).getYear() == -147) {
          res.detail.birthday = "";
          res.detail.birthday_day = "";
          res.detail.birthday_month = "";
          res.detail.birthday_year = "";
        }
        res.detail.is_ready = true;
        commit("setShortProfile", res.detail);
        // if (!res.detail.user_guid) {
        //   router.push("/validatetelephone");
        // }
        return res.detail;
      } else {
        commit("setShortProfile", false);
      }
    },
    setCompleteProfile: async ({ commit, rootGetters, state }, payload) => {
      state.completeProfile = payload.detail;
      // commit("completeProfile", res.detail);
    },
    refreshUserProfile: async ({ commit, rootGetters }) => {
      await axios({
        url: `${process.env.VUE_APP_API}/api/v1/user/GetUserProfile`,
        headers: rootGetters["api/headers"],
        method: "get"
      }).then(
        res => {
          if (res.result == 1) {
            commit("setShortProfile", res.detail);
          } else {
            commit("setShortProfile", false);
          }
        },
        error => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
          }
        }
      );
    },
    login: async ({ commit, rootGetters }, payload) => {
      try {
        let body = {
          SocialProvider: "line",
          SocialId: payload.userId
        };
        const response = await axios.post(
          `${process.env.VUE_APP_API}/api/v1/user/LoginExternal`,
          body
        );
        if (response.result == 1) {
          return Vue.$cookies.set("hourglass_token", response.detail.token);
        } else {
          Vue.$cookies.remove("hourglass_register_success");
          Vue.$cookies.remove("hourglass_token");
          return router.push("/validatetelephone");
        }
      } catch (error) {
        return window.alert(error);
      }
    },
    lineLiffLogin: async ({ dispatch }, endPoint) => {
      try {
        // if()
        liff.ready
          .then(async () => {
            if (liff.isLoggedIn()) {
              return await dispatch("getProfileFromLine");
            } else {
              liff.login({
                redirectUri: endPoint
              });
            }
            // do something you want when liff.init finishes
          })
          .catch(err => console.log(err));
      } catch (error) {
        return window.alert(error);
      }
    },
    getProfileFromLine: async ({ commit, rootGetters, dispatch }) => {
      const profile = await liff.getProfile();
      Vue.$cookies.set("user_profile_token", profile, 60 * 60 * 24 * 30);
      await dispatch("login", profile);
      return profile;
    },
    getTheme: async ({ commit, rootGetters, dispatch }) => {
      const resp = await this.$axios(process.env.VUE_APP_THEME_API);
      commit("setTheme", resp.detail);
      return resp;
    }
  }
});
