<template>
  <div class="div-input">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-custom', { error: isValidate }]">
      <label v-if="textFloat" :class="className">
        {{ textFloat }} <span class="text-remark">{{ textRemark }}</span>
        <span v-if="isRequired">*</span>
      </label>
      <input
        :class="['custom-input f-regular']"
        :type="type"
        :placeholder="placeholder"
        :name="name"
        :required="required"
        :value="value"
        :size="size"
        @input="$emit('input', $event.target.value)"
        @change="onDataChange"
        @keypress="$emit('onkeypress', $event)"
        @paste="$emit('onPaste', $event)"
        @keyup="$emit('onkeyup', $event.target.value)"
        ref="input"
        :maxlength="maxlength"
        :disabled="isDisplay"
        :readonly="readonly"
        :oninput="oninput"
        :inputmode="type == 'number' ? 'numeric' : inputmode"
      />
    </div>
    <span v-if="detail" class="text-desc">{{ detail }}</span>

    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">
        {{ "Please fill the correct " + name }}
      </span>
      <!-- <span class="text-error" v-else-if="v.minLength == false"
        >กรุณากรอกอย่างน้อย {{ v.$params.minLength.min }} อักษร</span
      > -->
      <span
        class="text-error"
        v-else-if="v.minLength == false && textFloat == 'ปีเกิด'"
        >Please Select Birth date {{ v.$params.minLength.min }} ตัว</span
      >
      <span class="text-error" v-else-if="v.minLength == false">{{
        "Please fill the correct " + name
      }}</span>
      <span class="text-error" v-else-if="v.validateTotal == false"
        >กรุณาระบุจำนวนเงินให้ถูกต้อง</span
      >
      <span class="text-error" v-else-if="v.email == false"
        >Please Input Email (Ex. email@example.com)</span
      >
      <span class="text-error" v-else-if="v.integer == false"
        >Please input only number</span
      >
      <span class="text-error" v-else-if="v.maxLength == false"
        >Please input no more than {{ v.$params.maxLength.max }} อักษร</span
      >
      <span class="text-error" v-else-if="v.decimal == false"
        >Please input only number</span
      >
      <span class="text-error" v-else-if="v.minValue == false"
        >Please input only number</span
      >

      <span class="text-error" v-else-if="v.alpha == false"
        >กรอกเฉพาะตัวอักษรเท่านั้น</span
      >
      <span class="text-error" v-else-if="v.alphaNumEng == false"
        >กรอกเฉพาะตัวอักษรภาษาอังกฤษและตัวเลขเท่านั้น</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: true,
      type: String
    },
    className: {
      required: false,
      type: String
    },
    placeholder: {
      required: true,
      type: String
    },
    type: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    detail: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    isDisplay: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    maxlength: {
      required: false,
      type: [Number, String, Boolean]
    },
    oninput: {
      required: false,
      type: String
    },
    inputmode: {
      required: false,
      type: String
    },
    textRemark: {
      required: false,
      type: String
    },
    validateText: {
      required: false
    },
    readonly: {
      required: false,
      default: false
    }
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event.target.value, event);
    },
    focus() {
      this.$refs.input.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.div-input {
  // margin-bottom: 1rem;
  // position: relative;
  /* white-space: nowrap; */
}
.input-custom {
  padding: 0px;
}
.input-custom > input:disabled {
  background-color: #f9f9f9;
  color: #575757;
  border-color: var(--disable-color);
}

.input-custom > input {
  // color: #575757;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 0px !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;

  -khtml-border-radius: 0;
  height: 45px;
  padding: 5px 10px;
}
.input-custom > input[size="lg"] {
  height: 45px;
}
.input-custom > input:focus {
  border: 1px solid var(--primary-color);
  // border-radius: 0.25rem;
}
.input-custom.error > input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: rgba(22, 39, 74, 0.4);
  font-size: var(--text-sm);
}
// .display-only {
//   position: absolute;
//   z-index: 5000;
//   width: 100%;
//   height: 100%;
//   border-radius: 10px;
// }
.text-error {
  color: #ff0000;
  font-size: 11px;
}
.text-remark {
  font-size: var(--text-md);
  color: gray;
}
</style>
