<template>
  <footer class="footer shadow-lg">
    <b-button
      variant="primary-theme"
      class="w-100"
      @click="$emit('onClick')"
      :disabled="isDisable"
      >{{ text }}</b-button
    >
  </footer>
</template>

<script>
export default {
  props: {
    isDisable: {
      required: false,
      type: Boolean
    },
    text: {
      required: true,
      type: String
    }
  },
  created() {
    let bodyStyles = document.body.style;

    bodyStyles.setProperty("--padding-footer", "130px");
  },
  beforeDestroy() {
    // console.log("object");
    // let bodyStyles = document.body.style;
    // bodyStyles.setProperty("--padding-footer", "80px");
  },
  destroyed() {
    console.log("destryo");
  }
};
</script>

<style lang="scss">
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  button {
    background-color: var(--primary-color);
  }
}
</style>
