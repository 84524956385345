<template>
  <div>
    <template v-if="$store.state.theme.line_layout == 'Compact'">
      <div class="navbar justify-content-start layout-2">
        <div>
          <img
            :src="$store.state.domain + $store.state.theme.themeLogoLine"
            class="logo-img"
            @click="toProfile"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="navbar justify-content-center layout-1">
        <div v-if="!isShowing">
          <img
            :src="$store.state.domain + $store.state.theme.themeLogoLine"
            class="logo-img"
            @click="toProfile"
          />
        </div>
        <div v-else>
          <img
            :src="$store.state.domain + $store.state.theme.themeLogoLine"
            class="logo-img"
            @click="toProfile"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    isShowing() {
      if (this.$cookies.get("hourglass_token")) return true;
      return false;
    }
  },
  data() {
    return {
      domain: process.env.VUE_APP_THEME_IMG_DOMAIN
    };
  },
  methods: {
    toProfile() {
      if (
        this.$route.path == "/register" ||
        this.$route.path == "/validatetelephone"
      ) {
        return;
      }
      this.$router.push("/profile");
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-black {
  background-color: #000;
}
.logo-img {
  width: auto;
  max-width: 200px;
  height: auto;
  max-height: 55px;
}
.btn-go-home {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
}
.navbar {
  // overflow: hidden;
  // position: sticky;
  // top: 0;
  background: white;

  width: 100%;
  // z-index: 5;
  // margin-top: 0.5rem;
  margin-bottom: -1%;
  justify-content: center;
  // background-color: var(--theme-navigation-bar-color);
  // transition: background-color 0.1s linear;
  // box-shadow: 1px 1px 5px -3px black;
}
.layout-1 {
  height: 80px;
  .logo-img {
    width: auto;
    max-width: 200px;
    height: auto;
    max-height: 65px;
  }
}
.layout-2 {
  height: 70px;
  .logo-img {
    width: auto;
    max-width: 200px;
    height: auto;
    max-height: 50px;
  }
}
</style>
